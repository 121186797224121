/* eslint-disable */
import moment from "moment";
import { AMOUNT_FORMAT_REGEX, DEFAULT_DROPDOWN_OPTION, EMPTY_STRING, VERIFICATION_EXPIRY_DAYS } from "../constants/constants";
import { ApiResponse, DropdownOptions, DropdownValue } from "../interface/globalInterface";

function validateApiResponse(
  response: ApiResponse
): boolean {
  try {
    if (response && response.status == 200 && response.data) {
      return true;
    }
    return false;
  } catch (error: any) {
    console.log("Error in validateApiResponse: ", error?.message);
    return false;
  }
}

function sortByLabel(array: any[], label: string): any {
  try {
    const sortedArray = [...array];
    if (sortedArray.length) {
      sortedArray.sort((a: any, b: any) => (a[label] < b[label] ? -1 : 1));
    }
    return sortedArray;
  } catch (error: any) {
    console.log("Error in sortByLabel: ", error?.message);
    return array;
  }
}

function formDropdownOptions(
  dropdownData: any[] | undefined,
  labelName: string,
  valueName: string,
  sortBySequence: boolean = false,
  sortByNumberIncludes: boolean = false,
  sortingLabel: string = "displaySeq"
): DropdownOptions[] {
  try {
    let dropdownFormed: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];

    if (dropdownData?.length) {
      const inputDropdownArray = [...dropdownData];
      if (sortBySequence) {
        dropdownFormed = sortByLabel(dropdownFormed, sortingLabel);
      }
      if (sortByNumberIncludes) {
        dropdownFormed.sort((a: any, b: any) =>
          a[sortingLabel].localeCompare(b[sortingLabel], undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      }

      inputDropdownArray.forEach((DropdownOptions: any) => {
        dropdownFormed.push({
          label: DropdownOptions[labelName],
          value: DropdownOptions[valueName],
        });
      });
    }

    return dropdownFormed;
  } catch (error: any) {
    console.log("Error in formDropdownOptions: ", error?.message);
    return [...DEFAULT_DROPDOWN_OPTION];
  }
}
function updateDescriptions(data: any) {
  return data.map((item: any) => {
    if (item.description === "Driver License") {
      item.description = "Drivers License";
      item.referenceCode = "na_dl";
    } else if (item.description === "Passport") {
      item.description = "Passport";
      item.referenceCode = "passport";
    }
    else if (item.description === "Other") {
      item.referenceCode = "other";
    }
    return item;
  });
}

const valueExistsInDropdown = (
  dropdownOptions: DropdownOptions[],
  selectedValue: string
): string => {
  try {
    let value: string = EMPTY_STRING;

    const matchedOption = dropdownOptions?.find(
      (option: DropdownOptions) => option.value == selectedValue
    );
    if (matchedOption) {
      value = selectedValue;
    }

    return value;
  } catch (error: any) {
    console.log("Error in isValueExistsInDropdown: ", error?.message);
    return '';
  }
}

const capitalizeFirstLetters = (input: string) => {

  const words: string[] = input.split(' ');
  const capitalizedWords: any = [];
  words.forEach((element: string) => {
    if (element !== '') {
      capitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    }
  });
  let captilizedInput = capitalizedWords.join(' ');

  if (words[words.length - 1] == '') {
    captilizedInput = captilizedInput + ' '
  }
  return captilizedInput;

};

const trimTextvalue = (input: string) => {
  let textValue: string = input;
  try {
    if (textValue && textValue.trim()?.length) {
      textValue = textValue.trim();
    }
  } catch (error: any) {
    console.log('Error in trimInput: ', error?.messsage);
  }
  finally {
    return textValue
  }
}

const formatDate = (inputDate: string) => {
  try {
    let formattedDate: string = inputDate;

    if (formattedDate) {

      if (formattedDate.includes('Z')) {
        formattedDate = formattedDate.replace('Z', '');
      }

      formattedDate = moment(formattedDate).format("YYYY-MM-DD");
    }
    return formattedDate;
  } catch (error: any) {
    console.log("Error in formatDate: ", error?.message);
    return EMPTY_STRING;
  }
}

const formatAmount = (inputAmount: string) => {
  try {
    let formattedAmount: string = parseFloat(inputAmount).toFixed(2).replace(AMOUNT_FORMAT_REGEX, ",");
    return formattedAmount;
  } catch (error: any) {
    console.log("Error in formatAmount: ", error?.message);
    return inputAmount;
  }
}

const formatByNumeric = (input: string): string => {
  try {
    let onlyNumeric: string = input;
    if (input?.length) {
      return (onlyNumeric).replace(/\D/g, '');
    }
    return onlyNumeric;

  } catch (error: any) {
    console.log("Error in formatByNumeric: ", error?.message);
    return input;
  }
}
const formatPhone = (input: string): string => {

  try {
    let phoneNumber: string = input
    if (input && input.length > 0) {
      const cleaned = formatByNumeric(input);
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      phoneNumber = cleaned.replace(PhoneRegex, '($1) $2-$3');
    }
    return phoneNumber;
  } catch (error: any) {
    console.log("Error in formatPhone: ", error?.message);
    return input;
  }
};

const buildVechicleYearDropdown = (): DropdownOptions[] => {
  const yearDropdown: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
  try {
    const currentDate: Date = new Date();
    const currentYear: number = currentDate.getFullYear();
    const fiftyYearsBack: number = currentDate.getFullYear() - 50;

    for (let i = currentYear; i > fiftyYearsBack; i--) {
      yearDropdown.push({
        value: i.toString(),
        label: i.toString(),
      });
    }
    return yearDropdown;
  } catch (error: any) {
    console.log("Error in buildVechicleYearDropdown: ", error?.message);
  }
  finally {
    return yearDropdown;
  }

};

const validateVerificationDate = (verifiedDate: string, expiryDays: string): string => {
  try {
    if (verifiedDate) {
      const verificationExpiryDays: string = expiryDays || VERIFICATION_EXPIRY_DAYS;

      let expirationDate = new Date(verifiedDate);
      expirationDate.setDate(expirationDate.getDate() + parseInt(verificationExpiryDays));

      const currentDate = moment(new Date(), 'YYYY-MM-DD');
      const expiresOnDate = moment(expirationDate, 'YYYY-MM-DD');

      const daysToExpire: number = expiresOnDate.diff(currentDate, 'days');

      if (daysToExpire > 0) {
        return verifiedDate;
      }
    }

    return EMPTY_STRING;

  } catch (error: any) {
    console.log("Error in validateVerificationDate: ", error?.message);
    return EMPTY_STRING;
  }
}

const maskLengthyText = (Text: any, MaxLength: number, Mask: any) => {
  let maskedValue: string = '-';
  try {
    maskedValue = !Text ? "-" : Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  }
  catch (error: any) {
    console.log('Error in maskLengthyText: ', error?.message);
  }
  finally {
    return maskedValue;
  }
};

// Function for checking whether the given year exists in the year dropdown
const isDropDownValueExist = (options: DropdownValue[], value: string): boolean => {
  return options.some((option) => option?.value === value);
};

// For formatting the text
const formatTextChange = (value: any) => {
  return value && value.trim().length > 0 ? value.trim() : '';
};

const validateValue = (value: any) => {
  let valid = false;
  if (value) {
    valid = true;
  }
  return valid;
}

function validateEmail(inputText: any) {
  const mailformat = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return mailformat.test(inputText);
}

const buildDate = (date: string) => {
  const d = date == '' ? new Date() : date;
  return moment(d).format('YYYY/MM/DD').replaceAll('/', '-');
};
const buildPaySchduleDD = (getEmployOptions: any) => {
  console.log('getEmployOptions', getEmployOptions);
  const referenceDetails = getEmployOptions.data.referenceOptionsDTO.referenceDetails;
  const dayPaid = getEmployOptions.data.dayspaidDTO.dayPaid;

  // Sort referenceDetails by display Sequence
  const sortedReferenceDetails = referenceDetails.sort((a: any, b: any) => (a.displaySeq - b.displaySeq));

  // Create the paidDropDown array
  const paidDropDown = sortedReferenceDetails.flatMap(
    (value: any, index: number) => {
      if (index === 0) {
        return [
          {
            label: "Select",
            value: "",
            name: "",
          },
          {
            label: value.description,
            value: value.referenceCode,
            name: value.description,
          },
        ];
      }
      return {
        label: value.description,
        value: value.referenceCode,
        name: value.description,
      };
    }
  );

  const sortedDaysPaid = dayPaid.sort((a: any, b: any) => (a.displaySeq - b.displaySeq));

  const daysPaidDropDown = sortedDaysPaid.flatMap((value: any, index: number) => {
    if (index === 0) {
      return [
        {
          label: "Select",
          value: "",
          name: "",
        },
        {
          label: value.daysPaidDescEn,
          value: value.refCode,
          name: value.daysPaidDescEn,
        }
      ];
    }
    return {
      label: value.daysPaidDescEn,
      value: value.refCode,
      name: value.daysPaidDescEn,
    };
  });

  return {
    paidDropDown,
    daysPaidDropDown
  };
}


export {
  validateApiResponse,
  sortByLabel,
  formDropdownOptions,
  capitalizeFirstLetters,
  valueExistsInDropdown,
  formatDate,
  formatAmount,
  formatPhone,
  buildVechicleYearDropdown,
  validateVerificationDate,
  trimTextvalue,
  formatByNumeric,
  maskLengthyText,
  isDropDownValueExist,
  formatTextChange,
  validateValue,
  validateEmail,
  buildDate,
  buildPaySchduleDD,
  updateDescriptions
}