/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardContent,
  Grid,
  RACButton,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import {
  ParamType,
  ReferenceDetail,
  ReferenceInfoProps,
} from "../../../interface/globalInterface";
import ReferenceAddressDetails from "./ReferenceAddressDetails";
import ReferenceDetails from "./ReferenceDetails";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import ContactInfoHeader from "../ContactInfoHeader";
import {
  EMPTY_STRING,
  MODULE_NAME,
  NON_FAMILY_RELATION_TYPES,
  REFERENCE_INFO_ERROR_MESSAGE,
  REFERENCE_INFO_PRIMARY_HEADING,
  REFERENCE_INFO_SECONDARY_HEADING,
  RELATIVE_RELATIONS,
} from "../../../constants/constants";
import { VerificationComponent } from "../VerifiedComponent";
import { ReferenceInformation } from "../../../interface/updateCustomerInterface";
import { isNotNullVariable, updateCustomerAndVerification } from "../Utils";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";

export default function ReferenceDetailsContainer({
  setterData,
}: ReferenceInfoProps) {
  const history = useHistory()

  const classes = globalStyles();
  const {
    configurations,
    updatedReferenceDetails,
    setUpdatedReferenceDetails,
    referenceErrorMessage,
    setReferenceErrorMessage,
    currentRole,
    setScreenLoader,
    setGetCustomerInfoResponse,
    setGetApprovalRes,
    getApprovalRes,
    currentEmpId,
    setReferenceErrorTitle,
    referenceEditEnabled,
    setReferenceEditEnabled,
    referenceErrorTitle
  } = useContext(UpdateCustomerContext);
  const { referenceInfo, setReferenceInfo } = setterData;
  const [showAuthentication, setShowAuthentication] = useState<boolean>(false);
  useEffect(() => {
    console.log("Reference Object", referenceInfo, updatedReferenceDetails);
    let familyReference = 0;
    let nonFamilyReference = 0;
    updatedReferenceDetails.forEach((element: ReferenceInformation) => {
      if (RELATIVE_RELATIONS.includes(element.referenceInfo.relationShip))
        familyReference++;
      if (
        NON_FAMILY_RELATION_TYPES.includes(element.referenceInfo.relationShip)
      )
        nonFamilyReference++;
      console.log("Error Message", familyReference, nonFamilyReference);
      if (familyReference >= 1 || nonFamilyReference >= 2)
        setReferenceErrorTitle("");
      else setReferenceErrorTitle(REFERENCE_INFO_ERROR_MESSAGE);
    });
  }, [updatedReferenceDetails]);

  const { customerId } = useParams<ParamType>();

  const primaryVerifyButtonValidation: boolean =
    configurations?.EnableCustomerVerificationPhaseTwo ? (!!updatedReferenceDetails[0].referenceInfo.referenceName && !!updatedReferenceDetails[0].referenceInfo.phoneNumber &&
      !!updatedReferenceDetails[0].referenceInfo.relationShip &&
      RELATIVE_RELATIONS.includes(updatedReferenceDetails[0].referenceInfo.relationShip)) :
      (!!updatedReferenceDetails[0].referenceInfo.relativeFirstName &&
        !!updatedReferenceDetails[0].referenceInfo.relativeLastName &&
        !!updatedReferenceDetails[0].referenceInfo.phoneNumber &&
        !!updatedReferenceDetails[0].referenceInfo.relationShip &&
        RELATIVE_RELATIONS.includes(updatedReferenceDetails[0].referenceInfo.relationShip));

  const secondaryVerifyButtonValidation: boolean =
    configurations?.EnableCustomerVerificationPhaseTwo ? (!!updatedReferenceDetails[1]?.referenceInfo?.referenceName && !!updatedReferenceDetails[1]?.referenceInfo?.phoneNumber &&
      !!updatedReferenceDetails[1]?.referenceInfo?.relationShip) :

      (!!updatedReferenceDetails[1]?.referenceInfo?.relativeFirstName &&
        !!updatedReferenceDetails[1]?.referenceInfo?.relativeLastName &&
        !!updatedReferenceDetails[1]?.referenceInfo?.phoneNumber &&
        !!updatedReferenceDetails[1]?.referenceInfo?.relationShip);

  const primarySaveButtonValidation: boolean =
    !!updatedReferenceDetails[0].referenceInfo.relativeFirstName &&
    !!updatedReferenceDetails[0].referenceInfo.relativeLastName &&
    !!updatedReferenceDetails[0].referenceInfo.relationShip;

  const secondarySaveButtonValidation: boolean =
    !!updatedReferenceDetails[1]?.referenceInfo?.relativeFirstName &&
    !!updatedReferenceDetails[1]?.referenceInfo?.relativeLastName &&
    !!updatedReferenceDetails[1]?.referenceInfo?.relationShip;


  const setData = (isCancellable: boolean) => {
    if (isCancellable) {
      const newReferenceDetails = referenceInfo.filter(
        (item: ReferenceInformation) => !item.isCancellable
      );
      setReferenceInfo(newReferenceDetails);
      setUpdatedReferenceDetails(newReferenceDetails);
      setReferenceErrorMessage(newReferenceDetails.map(() => ({
        referenceInfo: {
          relativeFirstName: EMPTY_STRING,
          referenceName: EMPTY_STRING,
          relativeLastName: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          relationshipTypeDesc: EMPTY_STRING,
          bestTimeToCall: EMPTY_STRING,
          relationShip: EMPTY_STRING,
          doNotCall: false,
          isEdited: false,
        },
        referenceAddressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          isEdited: false,
        },
        referenceDetailsId: EMPTY_STRING,
        verified: false,
        verifiedDate: EMPTY_STRING,
        active: true,
        isEditEnabled: false,
        decisionEngineId: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        verifiedCV: false,
        verifiedBy: EMPTY_STRING,
        verifiedOn: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        isCancellable: true,
      })))
    } else {
      const newReferenceDetails: ReferenceInformation = {
        referenceInfo: {
          relativeFirstName: EMPTY_STRING,
          relativeLastName: EMPTY_STRING,
          referenceName: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          relationshipTypeDesc: EMPTY_STRING,
          bestTimeToCall: EMPTY_STRING,
          relationShip: EMPTY_STRING,
          doNotCall: false,
          isEdited: false,
        },
        referenceAddressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          isEdited: false,
        },
        referenceDetailsId: EMPTY_STRING,
        verified: false,
        verifiedDate: EMPTY_STRING,
        active: true,
        isEditEnabled: false,
        decisionEngineId: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        verifiedCV: false,
        verifiedBy: EMPTY_STRING,
        verifiedOn: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        isCancellable: true,
      };
      setReferenceInfo([...referenceInfo, newReferenceDetails]);
      setUpdatedReferenceDetails([
        ...updatedReferenceDetails,
        newReferenceDetails,
      ]);
      setReferenceErrorMessage([...referenceErrorMessage, newReferenceDetails]);
    }
  };

  const updateReferenceDetails = async (isAuthenticated: boolean) => {
    console.log('isRequredDetials', referenceErrorTitle);
    if (isAuthenticated || !updatedReferenceDetails[0].verified) {
      setShowAuthentication(false);
      setScreenLoader(true);
      const payload: any = [];
      updatedReferenceDetails?.forEach(
        (element: ReferenceInformation, index: number) => {
          payload.push({
            personalReferenceId: element.referenceDetailsId,
            decisionId: index == 0 ? element.decisionEngineId : undefined,
            verifyCode: index == 0 ? element.verifyCode : undefined,
            modifiedBy: index == 0 ? currentEmpId : undefined,
            verified: index == 0 ? element.verified : undefined,
            firstName: configurations?.EnableCustomerVerificationPhaseTwo ? element.referenceInfo.referenceName : element.referenceInfo.relativeFirstName,
            phoneNumber: element.referenceInfo.phoneNumber?.replace(/\D/g, ""),
            lastName: configurations?.EnableCustomerVerificationPhaseTwo ? null : element.referenceInfo.relativeLastName,
            relationshipType: element.referenceInfo.relationShip,
            relationshipTypeDesc: element.referenceInfo.relationshipTypeDesc,
            bestTimeToCall: isNotNullVariable(
              element.referenceInfo.bestTimeToCall
            )
              ? element.referenceInfo.bestTimeToCall
              : undefined,
            doNotCall: element.referenceInfo.doNotCall ? "Y" : "N",
            isRequiredDetailsProvided: index == 0 ? referenceErrorTitle == '' ? true : false : undefined
          });
        }
      );
      const updateCustomerReference = await updateCustomerAndVerification(
        payload,
        "REF",
        customerId || "",
        setGetCustomerInfoResponse,
        setGetApprovalRes,
        "", "", "",
        configurations?.EnableCustomerVerificationPhaseTwo,
        history,
        getApprovalRes
      );
      // eslint-disable-next-line no-console
      console.log("updateCustomerReference", updateCustomerReference);
      if (updateCustomerReference) {
        if (updatedReferenceDetails[0].verified) {
          const referenceDetails = [
            {
              ...updatedReferenceDetails[0],
              isEditEnabled: true,
              verified: true,
              verifiedBy: currentEmpId,
              verifiedSource: "RACPad",
              verifiedOn: moment().format("MM/DD,YYYY"),
              isCancellable: false,
            },
            ...(payload.length == 2
              ? [
                {
                  ...updatedReferenceDetails[1],
                  isEditEnabled: true,
                  verified: true,
                  verifiedBy: currentEmpId,
                  verifiedSource: "RACPad",
                  verifiedOn: moment().format("MM/DD/YYYY"),
                  isCancellable: false,
                },
              ]
              : []),
          ];
          console.log("Inside If Reference Details", referenceDetails);
          setReferenceEditEnabled(true);
          setUpdatedReferenceDetails(referenceDetails);
          setReferenceInfo(referenceDetails);
        } else {
          const referenceDetails = [
            {
              ...updatedReferenceDetails[0],
              isEditEnabled: true,
              verified: false,
              isCancellable: false,
            },
            ...(payload.length == 2
              ? [
                {
                  ...updatedReferenceDetails[1],
                  isEditEnabled: true,
                  verified: false,
                  isCancellable: false,
                },
              ]
              : []),
          ];
          console.log("Inside else Reference Details", referenceDetails);
          setUpdatedReferenceDetails(referenceDetails);
          setReferenceInfo(referenceDetails);
          setReferenceEditEnabled(true);
        }
      }
      setScreenLoader(false);
    } else if (!isAuthenticated) {
      setShowAuthentication(true);
    }
  };

  const validateReferenceDetails = () => {
    let isErrorPresent = false;
    const errorMessageInfo = [...referenceErrorMessage];
    console.log("referenceInfo", referenceInfo);
    updatedReferenceDetails.forEach(
      (element: ReferenceInformation, index: number) => {

        if (configurations?.EnableCustomerVerificationPhaseTwo) {
          if (!isNotNullVariable(element.referenceInfo.referenceName)) {
            errorMessageInfo[index].referenceInfo.referenceName = "Required";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.referenceName = "";
          }
          if (!isNotNullVariable(element.referenceInfo.phoneNumber)) {
            errorMessageInfo[index].referenceInfo.phoneNumber = "Required";
            isErrorPresent = true;
          } else if (element.referenceInfo.phoneNumber?.length < 10) {
            errorMessageInfo[index].referenceInfo.phoneNumber =
              "Enter Valid Phone Number";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.phoneNumber = "";
          }
          if (!isNotNullVariable(element.referenceInfo.relationShip)) {
            errorMessageInfo[index].referenceInfo.relationShip = "Required";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.relationShip = "";
          }
        }
        else {
          if (!isNotNullVariable(element.referenceInfo.relativeFirstName)) {
            errorMessageInfo[index].referenceInfo.relativeFirstName = "Required";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.relativeFirstName = "";
          }
          if (!isNotNullVariable(element.referenceInfo.relativeLastName)) {
            errorMessageInfo[index].referenceInfo.relativeLastName = "Required";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.relativeLastName = "";
          }
          if (!isNotNullVariable(element.referenceInfo.phoneNumber)) {
            errorMessageInfo[index].referenceInfo.phoneNumber = "Required";
            isErrorPresent = true;
          } else if (element.referenceInfo.phoneNumber?.length < 10) {
            errorMessageInfo[index].referenceInfo.phoneNumber =
              "Enter Valid Phone Number";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.phoneNumber = "";
          }
          if (!isNotNullVariable(element.referenceInfo.relationShip)) {
            errorMessageInfo[index].referenceInfo.relationShip = "Required";
            isErrorPresent = true;
          } else {
            errorMessageInfo[index].referenceInfo.relationShip = "";
          }
        }

      }
    );
    console.log("errorMessageInfo", errorMessageInfo);
    setReferenceErrorMessage(errorMessageInfo);
    return !isErrorPresent;
  };

  const handleInlineEdit = () => {
    return (
      <Grid container md={12} className={`${classes.mt10px}`}>
        <Grid item md={10}>
          {referenceInfo[0].verified ? (
            <DynamicVerifiedStatus
              value={{
                verifiedBy: referenceInfo[0].verifiedBy,
                verifiedSource: referenceInfo[0].verifiedSource,
                verifiedOn: referenceInfo[0].verifiedDate,
              }}
            />
          ) : null}
        </Grid>
        <Grid item md={2} className={`${classes.alignRight}`}>
          {!referenceEditEnabled ? (
            <RACButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setReferenceEditEnabled(true);
                setData(true);
              }}
            >
              Cancel
            </RACButton>
          ) : null}
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.ml10px} ${classes.alignRight}`}
            // disabled={!referenceEditEnabled ? referenceInfo.length == 1 ? !primarySaveButtonValidation : referenceInfo.length == 2 ? !secondarySaveButtonValidation : false : false}
            onClick={async () => {
              if (referenceEditEnabled) {
                setReferenceEditEnabled(false);
                setUpdatedReferenceDetails(
                  (prevData: ReferenceInformation[]) => {
                    return [
                      { ...prevData[0], ...{ verified: false } },
                      ...prevData.slice(1),
                    ];
                  }
                );
              } else {
                if (validateReferenceDetails()) updateReferenceDetails(false);
              }
            }}
          >
            {`${!referenceEditEnabled ? "Save" : "Edit"}`}
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const getAnotherReference = () => {
    return (
      <Typography
        className={
          configurations.customerVerificationOptimization && referenceEditEnabled
            ? `${classes.racBlue} ${classes.p10px0px} ${classes.pointerEvent}`
            : `${classes.racBlue} ${classes.p10px0px}`
        }
      >
        <RACButton
          className={`${classes.backgroundNone} ${classes.borderNone} ${classes.removeButtonHover}`}
          disabled={
            configurations.customerVerificationOptimization
              ? referenceEditEnabled
              : false
          }
          onClick={() => {
            validateReferenceDetails() ? setData(false) : null;
          }}
        >
          {configurations.customerVerificationOptimization
            ? `+ Add Secondary Reference`
            : `+ Add another Reference`}
        </RACButton>
      </Typography>
    );
  };

  const renderReferenceBody = (
    element: ReferenceInformation,
    index: number
  ) => {
    return (
      <Grid container className={`${classes.p10px5px}`}>
        {configurations.customerVerificationOptimization ? (
          <ContactInfoHeader
            value={
              index == 0
                ? REFERENCE_INFO_PRIMARY_HEADING
                : REFERENCE_INFO_SECONDARY_HEADING
            }
          />
        ) : null}
        <ReferenceDetails
          value={
            !referenceEditEnabled
              ? updatedReferenceDetails[index].referenceInfo
              : element.referenceInfo
          }
          setterData={
            !referenceEditEnabled
              ? {
                index,
                referenceInfo: updatedReferenceDetails,
                setReferenceInfo: setUpdatedReferenceDetails,
                editEnabled: referenceEditEnabled,
              }
              : {
                index,
                referenceInfo,
                setReferenceInfo,
                editEnabled: referenceEditEnabled,
              }
          }
        />
        {!configurations.customerVerificationOptimization ? (
          <ReferenceAddressDetails
            value={element.referenceAddressInfo}
            setterData={{ index, referenceInfo, setReferenceInfo }}
          />
        ) : null}
        {configurations.customerVerificationOptimization &&
          index == referenceInfo.length - 1 &&
          referenceInfo.length < 2
          ? getAnotherReference()
          : null}
        {configurations.customerVerificationOptimization &&
          (!referenceEditEnabled || !referenceInfo[0].verified) &&
          referenceInfo[0].decisionEngineId &&
          index == referenceInfo.length - 1 ? (
          <VerificationComponent
            value={{
              isButtonEnabled: false,
              validateInputFields: referenceInfo.length == 1 ? primaryVerifyButtonValidation : secondaryVerifyButtonValidation,
              editEnabled: !referenceEditEnabled,
              actualVerifiedValue: referenceInfo[0],
              updatedVerifiedValue: updatedReferenceDetails[0],
              setterFunction: setUpdatedReferenceDetails,
              isArrayOfObject: true,
              index: 0,
              stateVariable: updatedReferenceDetails as ReferenceDetail[],
            }}
          />
        ) : null}
        {/* Override Part Rendered Here */}
        {configurations.customerVerificationOptimization &&
          index == referenceInfo.length - 1
          ? handleInlineEdit()
          : null}
      </Grid>
    );
  };

  const renderReferenceCard = (
    element: ReferenceInformation,
    index: number
  ) => {
    return (
      <Card
        className={`${classes.width100p} ${classes.mt10px} ${classes.card}`}
        key={index}
      >
        <CardContent className={`${classes.width100p}`}>
          {renderReferenceBody(element, index)}
        </CardContent>
      </Card>
    );
  };

  const renderInlineEditReferences = () => {
    return (
      <Grid container>
        <Card
          className={`${classes.width100p} ${classes.mt10px} ${classes.card}`}
        >
          <CardContent className={`${classes.width100p}`}>
            {referenceInfo.map(
              (element: ReferenceInformation, index: number) =>
                renderReferenceBody(element, index) // Directly call the function here
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderEditableReferences = () => {
    return (
      <Grid container>
        {referenceInfo.map((element: ReferenceInformation, index: number) => (
          <>
            {renderReferenceCard(element, index)}
            {getAnotherReference()}
          </>
        ))}
      </Grid>
    );
  };
  return (
    <>
      {configurations.customerVerificationOptimization
        ? renderInlineEditReferences()
        : renderEditableReferences()}
      {showAuthentication ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setShowAuthentication(false);
          }}
          setTwoFactorCompleted={(e: any) => updateReferenceDetails(e)}
          moduleName={MODULE_NAME.REFERENCE_VERIFICATION}
          currentRole={currentRole}
        />
      ) : null}
    </>
  );
}
