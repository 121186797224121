/* eslint-disable */
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";

export const globalStyles = () => {
    const UseClasses = makeStyles((theme) => ({
        justifyEnd: {
            justifyContent: 'end'
        },
        racBlue: {
            color: RACCOLOR.NORMAL_BLUE
        },
        fontOpensansBold: {
            fontFamily: 'OpenSans-bold'
        },
        fontOpensansSemiBold: {
            fontFamily: 'OpenSans-semibold'
        },
        lightGreyColor: {
            color: '#B5B5B5'
        },
        fontSize14px: {
            fontSize: '14px'
        },
        fontSize13px: {
            fontSize: '13px'
        },
        fontSize13px: {
            fontSize: '13px'
        },
        inputTxt: {
            width: '65%'
        },
        fontSize16px: {
            fontSize: '16px'
        },
        floatRight: {
            float: 'right'
        },
        floatLeft: {
            float: 'left'
        },
        width100p: {
            width: '100%'
        },
        width50p: {
            width: '50%'
        },
        displayFlex: {
            display: 'flex'
        },
        justifyContentStart: {
            justifyContent: 'start'
        },
        justifyContentEnd: {
            justifyContent: 'end'
        },
        backgroundNone: {
            backgroundColor: 'transparent'
        },
        borderNone: {
            border: 'none'
        },
        removeButtonHover: {
            color: 'inherit',
            '&:hover': {
                border: 'none',
                backgroundColor: 'transparent',
                color: RACCOLOR.NORMAL_BLUE
            },
        },
        tableBackgroundColor: {
            backgroundColor: 'white',
        },
        borderBottom: {
            borderBottom: '2px solid #9a9a9a'
        },
        mt10px: {
            marginTop: '10px'
        },
        mt20px: {
            marginTop: '20px'
        },
        ml10px: {
            marginLeft: '10px'
        },
        ml5px: {
            marginLeft: '5px'
        },
        mt1px: {
            marginTop: '1px'
        },
        mt0px: {
            marginTop: '0px'
        },
        mt5px: {
            marginTop: '5px'
        },
        checkBoxColor: {
            color: '#0d6efd',
        },
        alignRight: {
            textAlign: 'right',
        },
        alignCheckBox: {
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
            display: 'inline-block',
            marginRight: '1rem',
            paddingLeft: 0,
        },
        mt19pxRem: {
            marginTop: theme.typography.pxToRem(19),
        },
        pl0: {
            paddingLeft: 0,
        },
        pt0: {
            paddingTop: 0
        },
        p10: {
            padding: '10px',
        },
        currentTextBoxStyle: {
            width: '87%',
            '& input': {
                textAlign: 'right',
            },
            borderBottomLeftRadius: '0px',
            borderTopLeftRadius: '0px',
        },
        customerDollerInputFiled: {
            width: '13%',
            paddingTop: '7px',
            paddingBottom: '6px',
            paddingLeft: theme.typography.pxToRem(10),
            paddingRight: theme.typography.pxToRem(10),
        },
        focusButton: {
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '1px 1px 6px 3px #80bdff',
            },
        },
        mt16pxRem: {
            marginTop: theme.typography.pxToRem(16)
        },
        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        formLabel: {
            marginBottom: '2px',
            float: 'left',
            color: '#111111',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },
        formLabelIdenity: {
            marginBottom: '2px',
            float: 'left',
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(14),
        },
        pl0: {
            paddingLeft: 0
        },
        displayInlineBlock: {
            display: 'inline-block'
        },
        flex0: {
            flex: '0 0 auto',
        },
        textWrapStyle: {
            whiteSpace: 'nowrap',
        },
        childDivDisplayFlex: {
            '&:div': {
                display: 'flex',
                backgroundColor: 'red'
            }
        },
        flexEnd: {
            justifyContent: 'flex-end'
        },
        pr2p: {
            paddingRight: '2%'
        },
        requiredColor: {
            color: '#FF0000'
        },
        greyTextColor: {
            color: '#656565'
        },
        widthAuto: {
            minWidth: '14.285%',
            maxWidth: 'auto'
        },
        tooltipBGColor: {
            backgroundColor: '#ECF0F3',
            boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
            display: 'inline-block',
            color: 'black',
            padding: '2%',
            width: '36.55%',
            fontFamily: 'OpenSans-semibold',
        },
        toolTipArrowColor: {
            color: '#ECF0F3',
            // fontSize: '16px'
        },
        ractabsStyle: {

            '& .Mui-selected': {
                '& .RAC-CUSTOMER--MuiTab-wrapper': {
                    color: '#0d6efd'
                },
            },

            '& div': {

                '& .RAC-CUSTOMER--MuiTabs-flexContainer': {
                    backgroundColor: 'white'
                },

            },
            '& .RAC-CUSTOMER--MuiTab-wrapper': {
                color: 'black'
            },
            '& #full-width-tabpanel-0': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-1': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-2': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-3': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-4': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-5': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-6': {
                width: '100%',
                padding: '1rem'
            },

        },

        mb100: {
            marginBottom: '100px'
        },

        mb8: {
            marginBottom: theme.typography.pxToRem(8),
        },

        mb16: {
            marginBottom: theme.typography.pxToRem(16),
        },

        mt24: {
            marginTop: theme.typography.pxToRem(24),
        },

        px2: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },

        title: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            marginBottom: '10px',
        },

        mb120: {
            marginBottom: '120px',
        },

        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },

        w100: {
            width: '100%',
        },

        textCenter: {
            textAlign: 'center',
        },

        mt16: {
            marginTop: theme.typography.pxToRem(16),
        },

        pt0: {
            paddingTop: 0,
        },

        fixTableHead: {
            overflowY: 'auto',
            maxHeight: '205px',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.2rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                    color: '#4A5174',
                },
            },
        },

        fixTableHeight300: {
            maxHeight: '300px',
        },

        fixTableHeight400: {
            maxHeight: '400px',
        },

        GridLoader: {
            textAlign: 'center',
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: '30px 0px',
        },

        lineBreak: {
            whiteSpace: 'nowrap',
        },

        customerTablecellbgcolor: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },

        pointerEvent: {
            pointerEvents: 'none',
            opacity: '0.6',
        },

        agrementGridMC: {
            '& > tbody > tr': {
                background: 'transparent',
            },
            '& td': {
                padding: '10px 5px',
            },
        },

        txtStart: {
            textAlign: 'start',
        },

        pl40: {
            paddingLeft: '32px!important',
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },

        agreementCategory: {
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },

        accordionIconGrid: {
            // backgroundImage: `url(${accordianOpenIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '23px',
            cursor: 'pointer',
            width: '23px',
            height: '23px',
            display: 'block',
            float: 'left',
            marginTop: '1px',
        },

        mR8: {
            marginRight: theme.typography.pxToRem(8),
        },

        accordionGridClose: {
            // backgroundImage: `url(${accordianCloseIcon})`,
            color: 'blue'
        },

        paymentIssue: {
            '& td': {
                backgroundColor: '#ffefef',
            },
            '& td:first-child': {
                borderTopLeftRadius: '7px',
                borderBottomLeftRadius: '7px',
            },
            '& td:last-child': {
                borderTopRightRadius: '7px',
                borderBottomRightRadius: '7px',
            },
        },

        agreementRow: {
            borderBottom: '2px solid white',
        },

        pl28: {
            paddingLeft: '28px!important',
        },

        racpadAgrLink: {
            borderLeft: '3px solid transparent',
            paddingLeft: '10px',
            borderRadius: '2px',
        },

        racpadPaymentInActive: {
            borderColor: '#b1adac',
        },

        racpadLink: {
            color: '#2468ff',
            fontSize: '14px',
            textDecoration: 'none',
            cursor: 'pointer',
        },

        racpadPaymentFails: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#fd6a63',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },

        racpadClubLink: {
            color: '#000000',
            fontSize: '14px',
            textDecoration: 'none',
        },

        racpadPaymentSuccess: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#56e0d8',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },

        focforLinkPaymentIssue: {
            outline: 'none',
        },

        badgeContainer: {
            padding: '1px 13px',
            borderRadius: '10px',
            backgroundColor: '#ddf8ed',
            color: '#10523e',
            marginLeft: ' 5px',
        },

        textRight: {
            textAlign: 'right',
        },

        pointerEvents: {
            pointerEvents: 'none',
            cursor: 'default'
        },

        positionRelative: {
            position: 'relative'
        },

        tooltipcss: {
            height: '35px',
            marginLeft: '4px',
            marginBottom: '-2px',
            top: '0',
            positon: 'absolute',
        },

        tooltipcss1: {
            height: '35px',
            marginLeft: '4px',
            marginBottom: '-2px',
            top: '0',
            positon: 'absolute',
            width: '700px',
            minWidth: '700px'
        },

        toolTipStyle: {
            top: '-45px',
            left: '0',
            backgroundColor: '#ECF0F3',
            color: 'black'
        },

        toolTipStyle1: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'black',
            // color: 'black'
        },
        toolTipStyle2: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'white',
            color: 'black',
            width: '700px',
            minWidth: '700px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: '10px 10px'
        },
        toolTipStyle3: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'white',
            color: 'black',
            width: '300px',
            minWidth: '300px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: '10px 10px'
        },
        toolTipStyle4: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'white',
            color: 'black',
            width: '590px',
            minWidth: '590px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: '10px 10px'
        },
        toolTipStyle5: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'white',
            color: 'black',
            minWidth: '247px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: '10px 10px'
        },
        toolTipStyle6: {
            top: '-47px',
            left: '-10',
            backgroundColor: 'white',
            color: 'black',
            width: '430px',
            minWidth: '430px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: '10px 10px'
        },
        arrowStyle: {
            // color: '#ECF0F3'
            color: 'black',
        },

        arrowStyle1: {
            color: '#ECF0F3'
        },

        iconWidth1: {
            width: '35px',
        },

        pointer: {
            cursor: 'pointer'
        },

        blur: {
            opacity: '0.5',
        },

        exchangeInProgress: {
            color: '#fcd209',
            fontWeight: 600,
            float: 'left',
        },

        custDigit: {
            width: '87%',
            '& input': {
                textAlign: 'right',
            },
            borderBottomLeftRadius: '0px',
            borderTopLeftRadius: '0px',
        },

        currencyDollarField: {
            width: '13%',
            paddingTop: '7px',
            paddingBottom: '6px',
            paddingLeft: theme.typography.pxToRem(10),
            paddingRight: theme.typography.pxToRem(10),
        },

        foc: {
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '1px 1px 6px 3px #80bdff',
            },
        },
        textWrapStyle: {
            whiteSpace: 'nowrap',
        },
        spacerPX2: {
            paddingLeft: theme.typography.pxToRem(8),
            paddingRight: theme.typography.pxToRem(8),
        },
        customerNorecords: {
            textAlign: 'center',
            marginTop: theme.typography.pxToRem(30),
            marginBottom: theme.typography.pxToRem(30),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
        },
        RACPOPMsg: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: 0,
        },
        customerTextcenter: {
            textAlign: 'center',
        },
        spacerMR2: {
            marginRight: theme.typography.pxToRem(8),
        },
        spacerMT2: {
            marginTop: theme.typography.pxToRem(16),
        },
        semiBold: {
            fontFamily: 'OpenSans-semibold',
        },
        spacerMB2: {
            marginBottom: theme.typography.pxToRem(8),
        },
        formCheck: {
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
            display: 'inline-block',
            marginRight: '1rem',
            paddingLeft: 0,
        },
        spacerMT4: {
            marginTop: theme.typography.pxToRem(24),
        },
        w100: {
            width: '100%',
        },
        foc: {
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '1px 1px 6px 3px #80bdff',
            },
        },
        spacerME2: {
            marginRight: theme.typography.pxToRem(16),
        },
        spacerMB3: {
            marginBottom: theme.typography.pxToRem(16),
        },
        RACPOPMsg: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: 0,
        },
        bold: {
            fontFamily: 'OpenSans-bold',
        },
        dispInlineFlex: {
            display: 'inline-flex',
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        me1: {
            marginRight: theme.typography.pxToRem(4),
        },
        me2: {
            marginRight: theme.typography.pxToRem(8),
        },
        mt1: {
            marginTop: theme.typography.pxToRem(4),
        },
        my3: {
            marginTop: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(16),
        },
        mt4: {
            marginTop: theme.typography.pxToRem(24),
        },
        pt01p: {
            padding: '0% 1%'
        },
        width15p: {
            width: '15%'
        },
        width10p: {
            width: '10%'
        },
        width20p: {
            width: '20%'
        },
        popUpHide: {
            display: 'none',
        },
        customMenuContainer: {
            border: '1px solid #2468FF',
        },
        popUp: {
            position: 'absolute',
            bottom: '100%',
            backgroundColor: 'white',
            width: '200px',
            padding: '15px',
            '& li': {
                listStyle: 'none',
            },
        },

        successicon: {
            paddingTop: '3px!important',
        },

        font16: {
            fontSize: '16px',
        },

        font14: {
            fontSize: '14px',
        },

        infoTextStyle: {
            color: 'gray',
            fontSize: theme.typography.pxToRem(13),
            textAlign: 'center',
        },

        pt2: {
            paddingTop: theme.typography.pxToRem(8),
        },

        mb5: {
            marginBottom: theme.typography.pxToRem(48),
        },

        mt3: {
            marginTop: theme.typography.pxToRem(16),
        },

        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },

        mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
        },
        racCol12: {
            flex: '0 0 auto',
            width: '100%',
        },
        racCol6: {
            flex: '0 0 auto',
            width: '50%',
        },
        mb3: {
            marginBottom: theme.typography.pxToRem(16),
        },
        racstrapTablecellBgColor: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        customerJustifycontentcenter: {
            justifyContent: 'center',
        },
        spacerMS2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        spacerMT1: {
            marginTop: theme.typography.pxToRem(4),
        },
        ps2: {
            paddingLeft: theme.typography.pxToRem(8),
        },
        masterLoader: {
            position: 'fixed',
            backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: 'center',
            margin: 0,
        },
        Loader: {
            display: 'block',
            position: 'fixed',
            zIndex: 1051,
            top: '50%',
            right: '50%',
        },
        spacerP2: {
            padding: theme.typography.pxToRem(16),
        },
        widgetTitle: {
            fontSize: theme.typography.pxToRem(16),
            fontFamily: 'OpenSans-bold',
            color: `${RACCOLOR.WASHED_BLACK}`,
        },
        datePicker: {
            paddingTop: '9px !important',
            marginBottom: '0px !important',
            '& div': {
                '& div': {
                    '& fieldset': {
                        borderColor: '#c4c4c4 !important',
                    },

                    '& input': {
                        padding: '6px',

                        paddingLeft: '12px',
                    },
                },
            },
        },
        datePickerAddCo: {
            marginBottom: '0px !important',
            marginTop: '4px !important',
        },
        formLabelAddCo: {
            marginBottom: '0.2rem',
            color: '#111111',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },
        ssnPaddingCocust: {
            padding: '6px 8px 8px 8px !important',
        },
        ssnTextField: {
            '& input': {
                ['@media (min-width:768px) and (max-width:1280px)']: {
                    padding: '7px 9px!important',
                },
            },
        },
        spacerMT0: {
            marginTop: theme.typography.pxToRem(0),
        },
        RACLoaderPage: {
            textAlign: 'center',
            marginTop: '250px',
            fontSize: '16px',
            marginLeft: '30px',
            marginRight: '30px',
            outline: 'none',
        },
        mt5: {
            marginTop: theme.typography.pxToRem(48),
        },
        mb2: {
            marginBottom: theme.typography.pxToRem(8),
        },
        ms2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        spacerMT3: {
            marginTop: theme.typography.pxToRem(16),
        },
        fixedBottomAddCo: {
            position: 'sticky',
            bottom: '-19px',
            right: '0px',
            backgroundColor: 'white',
            padding: '14px 0px',
        },
        racRed: {
            color: '#B61414'
        },
        p10px0px: {
            padding: '10px 0px'
        },
        p10px5px: {
            padding: '10px 5px'
        },
        pt05p: {
            padding: '0% 0.5%'
        },
        mt11px: {
            marginTop: '11px'
        },
        mr25p: {
            marginRight: '0.5%'
        },
        buttonsPadding: {
            padding: '9px 36px'
        },
        inputTextBoxClass: {
            width: '90%'
        },
        ModalCardClass: {
            '& div': {
                '& div': {
                    width: '342px'
                }
            }
        },
        TitleClassName: {
            width: '50% !important'
        },
        ms1: {
            marginLeft: theme.typography.pxToRem(4),
        },
        fontPrimaryColor: {
            color: '#2179FE'
        },
        buttonsColor: {
            background: '#F0F8FF !important',
            color: '#2179FE !important',
            borderRadius: '50px',
            marginTop: '10px',
            width: '46%'
        },
        buttonsColor1: {
            background: '#F0F8FF',
            color: '#2179FE',
            borderRadius: '5px',
            width: '20%',
            height: '80%',
            alignItems: 'center',
        },
        flexDirectionColumn: {
            flexDirection: 'column'
        },
        inputFiledMargin: {
            '& div:nth-child(2)': {
                marginTop: '10px',
            },
        },
        inputFiledMargin1: {
            '& div:nth-child(2)': {
                '& div': {
                    marginTop: '10px',
                }
            },
        },
        arrowStyle1: {
            // color: '#ECF0F3'
            color: 'white'
        },
        justifyContentSpaceAround: {
            justifyContent: 'space-around'
        },
        p10px: {
            padding: '10px'
        },
        customModal: {
            '& div': {
                '& div:nth-child(2)': {
                    '& div': {
                        minWidth: '900px',
                        minHeight: '250px',
                    }
                },
            },
        },
        resOwnDocumentUpload: {
            position: 'absolute',
            marginTop: '-70px',
            marginLeft: '25rem'
        },
        resUploadImageBtn: {
            background: '#F0F8FF !important',
            color: '#2179FE !important',
            borderRadius: '50px',
            marginTop: '10px',
            width: '150px'
        },
        viewPdfBtnColor: {
            borderRadius: '5px',
            width: '25%',
            padding: '10px'
        },
        residenceOption1Header: {
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '24.51px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#2179FE'
        },
        residenceRentalOrStyle: {
            width: '50px',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '11px',
            position: 'relative',

            '&::before': {
                borderLeft: '2px solid #C4C4C4',
                content: '""',
                display: 'block',
                width: '1px',
                position: 'absolute',
                zIndex: 0,
                height: '34px',
                left: '25px',
                top: '-23px',
            },
            '&::after': {
                borderLeft: '2px solid #C4C4C4',
                content: '""',
                display: 'block',
                width: '1px',
                position: 'absolute',
                zIndex: 0,
                height: '35px',
                left: '25px',
                top: '28px',
            }
        },
        fontDisabledColor: {
            color: '#B5B5B5'
        },
        buttonsDisableColor: {
            background: '#F0F8FF',
            color: '#B5B5B5',
            borderRadius: '5px',
            width: '20%',
            height: '80%',
            alignItems: 'center'
        },
        mtm3px: {
            marginTop: '-3px'
        },
        intellicheckIdType: {
            marginTop: '15px',
            '& div:nth-child(1)': {
                width: '91%'
            }

        }
    }));
    const classes = UseClasses();
    return classes;
}